document.addEventListener('DOMContentLoaded', () => {
  const expandBtn = document.querySelectorAll('.list-expand');
  expandBtn.forEach((btn) => {
    /* eslint-disable no-param-reassign */
    btn.addEventListener('click', () => {
      const expandId = document.getElementById(btn.getAttribute('aria-controls'));
      expandId.hidden = !expandId.hidden;
      btn.ariaExpanded = btn.ariaExpanded !== 'true';
    });
  });
});
